block<!--
 * ======================================
 * 说明： 考勤管理
 * 作者： Silence
 * 文件： attendance.vue
 * 日期： 2023/8/30 23:11
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <div class="mx-6 pl-4 pt-8">
      <el-form :inline="true" :model="formSearch" class="demo-form-inline">
        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo" @change="handleRefresh" @refresh="handleRefresh"></SemesterClassSelect>
      </el-form>
    </div>
    <Scrollbar>
      <Table :showPage="false" :options="handleOptions" :loadData="handleLoadData" ref="table" :handleInfo="{label:'操作',width:220}">
        <template #action>
          <div>
          <el-button type="success" @click="handleExport" icon="el-icon-plus">下载考勤模板</el-button>
          <el-upload
            action=""
            :show-file-list="false"
            style="display: contents"
            accept=".xls,.XLS,.xlsx,.XLSX"
            :http-request="handleUploadXlsx"
          >
            <el-button icon="el-icon-upload2" type="primary" class="ml-1"
            >导入考勤数据
            </el-button>
          </el-upload>
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from './components/attendanceEdit.vue'
import mixin from "@/mixin/execlExportImport";
export default {
  name: "medal",
  mixins: [mixin],
  data() {
    return {
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        semesterId:'',
        classId:'',
        number:'',
        realName:'',
        grade:''
      },
    };
  },
  methods: {
    handleLoadDataInfo(){
      this.handleRefresh();
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/web/attendance").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("attendance/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    handleEdit(row){
      this.$cloud.dialog({
        title: '更新考勤信息',
        data: row,
        component: edit,
        success: '更新考勤信息！',
        warning: '更新考勤信息！',
        refresh: this.handleRefresh,
        customClass: 'attendance-custom-dialog'
      })
    },
    // 下载考勤模板
    handleExport() {
      this.$cloud.get("student/list", {
        page: 1,
        size: 999999,
        classId:this.formSearch.classId
      }).then(res => {
        if(res?.children?.length){
          let tHeader = ["学期ID","年级ID","班级ID","班级","学号","姓名","性别","上课天数","实到天数","病假天数","事假天数"];
          let fields = ['semester_id','grade_id','class_id',"class_class_name","number","real_name","sex", "total","actual","sick","thing"];
          let formatJson = (fliterVal, tableData) => {
            return tableData.map((v) => fliterVal.map((j) => {
              switch ( j ){
                case 'semester_id':
                  return this.formSearch.semesterId;
                case 'grade_id':
                  return this.formSearch.grade;
                case 'class_id':
                  return this.formSearch.classId
                default:
                  return v[j]
              }
            }));
          };
          let excelData = formatJson(fields, res.children);
          this.exportElxs({
            header: tHeader,
            data:excelData, //数据
            filename: `学生考勤模板`,
            autoWidth: true,
            bookType: "xlsx",
          });
        }else{
          this.$message.error('暂无数据！')
        }
      })
    },

    // 导入考勤
    handleUploadXlsx(e){
      this.httpRequest(e,(data)=>{
        if(data && data.length){
          let params = {
            semesterId:data[0]['学期ID'],
            grade:data[0]['年级ID'],
            classId:data[0]['班级ID'],
            list:data.map(item=>{
              return {
                number:item['学号'],
                total:item['上课天数'],
                actual:item['实到天数'],
                sick:item['病假天数'],
                thing:item['事假天数'],
              }
            })
          }
          this.$cloud.post('attendance/import',params).then(res=>{
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },


    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss">
.attendance-custom-dialog{
  width: 450px;
}
</style>

<!--
 * ======================================
 * 说明：
 * 作者： Silence
 * 文件： attendanceEdit.vue
 * 日期： 2023/8/31 1:18
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-row>
      <el-col :span="8">
        <el-form-item label="年级">
          {{ value.classes_grade }}
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="班级">
          {{ value.classes_class_name }}
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="姓名">
          {{ value.student_real_name }}
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="上课天数" prop="total">
      <el-input-number class="w-full" v-model="form.total"></el-input-number>
    </el-form-item>
    <el-form-item label="实到天数" prop="actual">
      <el-input-number class="w-full" v-model="form.actual"></el-input-number>
    </el-form-item>
    <el-form-item label="病假天数" prop="sick">
      <el-input-number class="w-full" v-model="form.sick"></el-input-number>
    </el-form-item>
    <el-form-item label="事假天数" prop="thing">
      <el-input-number class="w-full" v-model="form.thing"></el-input-number>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        form: {
          studentId:'',
          grade:'',
          semesterId:'',
          classId:'',
          total:'',
          actual:'',
          sick:'',
          thing:''
        },
        rules:{
          total:[{ required: true, message: '请输入上课天数', trigger: 'blur' }],
          actual:[{ required: true, message: '请输入实到天数', trigger: 'blur' }],
          sick:[{ required: true, message: '请输入病假天数', trigger: 'blur' }],
          thing:[{ required: true, message: '请输入事假天数', trigger: 'blur' }],
        }
      }
    },
    computed: {},
    created() {},
    mounted() {
      if(this.value.id){
        this.handleLoadForm();
      }else{
        this.form={
          studentId:this.value.student_id,
            grade:this.value.grade,
            semesterId:this.value.semester_id,
            classId:this.value.classes_id,
            total:0,
            actual:0,
            sick:0,
            thing:0,
        }
      }
    },
    methods: {
      handleLoadForm(){
          this.$cloud.get('attendance/detail',{id:this.value.id}).then(res=>{
            console.log(res)
            this.form = res
          })
      },
  
      handleSubmit() {
        return new Promise( ( resolve , reject ) => {
          this.$refs['form'].validate( ( valid ) => {
            if ( valid ) {
              let api = this.value.id ? 'attendance/edit' : 'attendance/create'
              this.$cloud.post( api , this.form).then( ( res ) => {
                resolve( true )
              } ).catch( () => {
                reject( false )
              } )
            } else {
              reject( false )
            }
          } )
        } )
      }
    },
  }
</script>
<style lang="scss" scoped></style>